import Stones from "./stones"
import Metals from "./metals"

export default class Jewelry {
  constructor(){
    this.metals = Metals;
    this.stones = Stones;
  }

  get metals () {
    return this._metals
  }

  get stones () {
    return this._stones
  }

  set metals(value) {
    this._metals = value
  }
  set stones (value) {
    this._stones = value
  }

  getMetalById(metalId) {
    for (const key in this.metals) {
      const metal = this.metals[key];
      if (metal.id === metalId) {
        return metal
      }
    }
  }

  getStoneById(stoneId) {
    for (const key in this.stones) {
      const stone = this.stones[key];
      if (stone.id === stoneId) {
        return stone
      }
    }
  }

  getMetalTypeNumber(){
    const keys = Object.keys(this.metals)
    if(keys && this.metals[keys[0]]){
      return this.metals[keys[0]].type
    }
    return null
  }

  getStoneTypeNumber(){
    const keys = Object.keys(this.stones)
    if(keys && this.stones[keys[0]]){
      return this.stones[keys[0]].type
    }
    return null
  }
}