export default {
    whiteGold: {
        type: 3,
        id: 5,
        group: "metal",
        name: "White Gold",
        color: " #343434",
        image: require("@/assets/metals/10KW.png"),
        value: "10KW"
    },
    roseGold: {
        type: 3,
        id: 2,
        group: "metal",
        name: "Rose Gold",
        color: " #E8CCB0",
        image: require("@/assets/metals/10KR.png"),
        value: "10KR"
    },
    yellowGold: {
        type: 3,
        id: 8,
        group: "metal",
        name: "Yellow Gold",
        color: " #E19D00",
        image: require("@/assets/metals/10KY.png"),
        value: "10KY"
    },
    silver: {
        type: 3,
        id: 1,
        group: "metal",
        name: "Silver",
        color: " #343434",
        image: require("@/assets/metals/10KW.png"),
        value: "SS"
    },
    platinum: {
        type: 3,
        id: 11,
        group: "metal",
        name: "Platinum",
        color: " #E8CCB0",
        image: require("@/assets/metals/10KY.png"),
        value: "PT"
    }
}