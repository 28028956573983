export default {
    alexandrite: {
        type: 2,
        id: 30,
        name: "Alexandrite",
        image: require("@/assets/stones/CA.png"),
        value: "CA",
        color: " #3f6886"
    },
    amethyst: {
        type: 2,
        id: 9,
        name: "Amethyst",
        image: require("@/assets/stones/AM.png"),
        value: "AM",
        color: " #7e3ea2"
    },
    aquamarine: {
        type: 2,
        id: 10,
        name: "Aquamarine",
        image: require("@/assets/stones/A.png"),
        value: "A",
        color: " #72aadb"
    },
    yellowCitrine: {
        type: 2,
        id: 12,
        name: "Yellow Citrine",
        image: require("@/assets/stones/CT.png"),
        value: "CT",
        color: " #c4a444"
    },
    diamond: {
        type: 2,
        id: 29,
        name: "Diamond",
        image: require("@/assets/stones/DIA.png"),
        value: "DIA",
        color: " #c6c7c7"
    },
    greenEmerald:{
        type: 2,
        id: 8,
        name: "Green Emerald",
        image: require("@/assets/stones/EM.png"),
        value: "EM",
        color: " #326c34"
    },
    garnet: {
        type: 2,
        id: 14,
        name: "Garnet",
        image: require("@/assets/stones/GA.png"),
        value: "GA",
        color: " #76463e"
    },
    opal: {
        type: 2,
        id: 19,
        name: "Opal",
        image: require("@/assets/stones/OP.png"),
        value: "OP",
        color: " #dee7e6"
    },
    pinkSapphire: {
        type: 2,
        id: 4,
        name: "PinkSapphire",
        image: require("@/assets/stones/PK.png"),
        value: "PK",
        color: " #d77bb7"
    },
    ruby: {
        type: 2,
        id: 6,
        name: "Ruby",
        image: require("@/assets/stones/R.png"),
        value: "R",
        color: " #893e42"
    },
    sapphire: {
        type: 2,
        id: 2,
        name: "Sapphire",
        image: require("@/assets/stones/S.png"),
        value: "S",
        color: " #3d4c95"
    },
    pinkTourmaline: {
        type: 2,
        id: 20,
        name: "Pink Tourmaline",
        image: require("@/assets/stones/PT.png"),
        value: "PT",
        color: " #db83bf"
    }
  };
