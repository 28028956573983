<template>
    <v-hover v-slot="{hover}">
        <div
            class="stones-plate"
            :class="{'stones-plate-expanded': expanded}"
            @click="expanded = !expanded;"
            :style="{
                '--position-x': `${position.x}%`,
                '--position-y':`${position.y}%`,
                zIndex: hover? 100 : null
            }"
            @mouseover="$emit('hover')"
            @mouseleave="$emit('leave')">
            <div class="preview">
                <div class="stone">
                    <div class="fill-height stone-group">
                        <v-img
                            lazy-src="@/assets/_redesigne/icons/ic-lazy-img.svg"
                            max-width="39"
                            max-height="39"
                            min-width="39"
                            width="39"
                            :aspect-ratio="1"
                            :class="attachmentList.length > 1 ? '': 'mx-auto'"
                            v-for="(attachment, idx) in attachmentList"
                            :key="idx"
                            :src="getAttachment(attachment).image">
                        </v-img>
                    </div>
                </div>
            </div>
            <div
                class="detailed"
                v-show="expanded"
                :class="{ expanded: expanded }">
                <div class="stone-name" v-for="(attachment, idx) in attachmentList"
                     :key="idx">
                    {{ getAttachment(attachment).name }}
                    <v-btn
                        x-small
                        fab
                        depressed
                        width="13"
                        v-if="allowEdit"
                        height="13"
                        color="#424242"
                        class="ml-1 delete-btn"
                        dark
                        @click.stop="deleteComment(group.id, attachment)">
                        <img src="@/assets/_redesigne/icons/ic-close-circle.svg" alt="">
                    </v-btn>
                </div>
            </div>
        </div>
    </v-hover>
</template>

<script>
    import JewelryService from "@/services/jewelry"
    import { mapGetters } from "vuex";

    export default {
        props: {
            group: {
                type: Object,
                default: () => ({})
            },
            position: {
                type: Object,
                default: () => ({ x: 0, y: 0 })
            },
            allowEdit: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            selectWidth: null,
            expanded: false,
            dropdownOpen: false,
            selectedCutStone: "Fine Round",
            jewelry: null
        }),
        created() {
            this.jewelry = new JewelryService()
        },
        methods: {
            saveEditedComment() {
                const payload = {
                    stone_shape: this.group.stone_shape,
                    id: this.group.id
                };
                this.$emit("updateComment", payload);
                this.editableCommentText = null;
            },
            deleteComment(id, attachmentType) {
                if(this.attachmentList.length > 1) {
                    const fieldName = this.group.type === this.$config.commentType.metal ? "metal_type": "stone_type"
                    this.$emit("updateComment", { id, [fieldName]: this.attachmentList.filter(attach => attach !== attachmentType) });
                } else  {
                    this.$emit("delete", id);
                }
            },
            getAttachment(value) {
                if (this.attachmentType === "METAL") {
                    //return this.METALS.find((metal) => metal.value === value);
                    for (const key in this.jewelry.metals) {
                        const metal = this.jewelry.metals[key];
                        if (metal.value === value) {
                            return metal
                        }
                    }
                } else {
                    //return this.STONES.find((stone) => stone.value === value);
                    for (const key in this.jewelry.stones) {
                        const stone = this.jewelry.stones[key];
                        if (stone.value === value) {
                            return stone
                        }
                    }
                }
            }
        },
        computed: {
            ...mapGetters(["getCutStones"]),
            attachmentList() {
                if(this.group.type === this.$config.commentType.metal) {
                    return  this.group.metal_type ? [this.group.metal_type]: this.group.metals.map(item => item.type)
                } else  {
                    return this.group.stone_type ? [this.group.stone_type]: this.group.stones.map(item => item.type)
                }
            },
            attachmentType() {
                return this.group.type === this.jewelry.getMetalTypeNumber() ? "METAL" : "STONE";
            },
            stoneShapes() {
                return this.allowEdit ? this.getCutStones : this.getCutStones.filter(item => this.stoneShapesSelected.includes(item.id))
            },
            stoneShapesSelected: {
                get() {
                    return Array.isArray(this.group.stone_shape) ? this.group.stone_shape : [this.group.stone_shape]
                } ,
                set(val) {
                    this.group.stone_shape = val
                }
            }
        }
    };
</script>

<style
    scoped
    lang="scss"
    src="./style.scss"
></style>

<style
    lang="scss"
    scoped>
.delete-btn {
img{
    width: 12px;
}
}

.dropdown-wrap{
    margin-top: 4px;
}

::v-deep .dropdown-menu {
    --scroll-thumb: #ccc;
    --scrollbar-bg: transparent;
    --scroll-width: 4px;
    box-shadow: none !important;
    background: #888888;
    border-radius: 5px 5px !important;
    max-width: none;
    margin-top: -1px;
    width: 100%;

    scrollbar-width: thin !important;
    scrollbar-color: var(--scroll-thumb) var(--scrollbar-bg);


    &::-webkit-scrollbar {
        width: var(--scroll-width);
    }

    &::-webkit-scrollbar-track {
        background: var(--scrollbar-bg);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--scroll-thumb);
        border-radius: 9px;
    }

    .v-list {
        background: transparent !important;
        font-size: 10px !important;
        padding: 0;

        &-item {
            .v-list-item__action{
                padding: 0;
                min-width: 10px;
                margin: 0 6px 0 0;
            }
            .v-list-item__title {
                font-size: 10px;
                overflow: visible;
                white-space: normal;
            }

            .custom-checkbox{
                width: 10px;
                height: 10px;
                background: #c4c4c4;
                position: relative;
                &--active{
                    &:before{
                        content: '';
                        display: block;
                        width: 7px;
                        height: 7px;
                        background: #696969;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                    }
                }
            }

            &.v-list-item {
                padding: 0 4px;
                color: #ffffff !important;
                min-height: 20px !important;
            }

            &__content {
                padding: 0;
            }
        }
    }
}

.cut-select {
    &.v-select--is-menu-active {
        min-width: 95px;
        ::v-deep .v-input__control {
            .v-input__slot {
                border-radius: 5px 5px 0 0 !important;
            }
        }
    }

    ::v-deep .v-input__control {
        min-height: auto;
        .v-input__slot {
            box-sizing: border-box;
            width: 100%;
            padding: 0 4px !important;
            background: #888888 !important;
            border-radius: 5px !important;
            min-height: 14px;

            .v-select__selection {
                &--disabled{
                    color: #ffffff !important;
                }
                color: #ffffff !important;
                font-size: 10px;
                min-height: auto;
                line-height: 1;
            }

            .v-input__append-inner {
                font-size: 12px !important;
                color: #464646 !important;
            }

            .v-select__selection--comma {
                overflow: visible;
                margin: 0;
            }

            .v-select__selections {
                input {
                    position: absolute;
                }
            }
        }
    }
}
</style>
